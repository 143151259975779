import { useState } from 'react'
import { Button, Checkbox, Col, Divider, Input, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import ShortUniqueId from 'short-unique-id'
import { getPartStl } from '$api/client'
import AddressFormItems from '$components/AddressFormItems'
import Form from '$components/Form'
import useAvailableMaterials from '$utils/useAvailableMaterials'
import { useCompany } from '$context/user'
import { StyledForm } from '../../Defaultwizard/styledComponents'
import OrderPartsTable, { updateMainAmount } from './OrderPartsTable'

const { TextArea } = Input

const StepTwoConfigOrderPart = ({
  bookableParts,
  setConfigState,
  setAccumulatedFormData,
  setOrderPartsListData,
  orderToRepeat,
}) => {
  const uid = new ShortUniqueId({ length: 8, dictionary: 'alphanum_upper' })

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const companyData = useCompany()

  const [isBillingSameAsDeliveryAddress, setIsBillingSameAsDeliveryAddress] =
    useState(true)
  const [processingParts, setProcessingParts] = useState([])

  const orderNumber = uid.rnd()

  const availableMaterials = useAvailableMaterials()

  const onFinish = async (values) => {
    // set status to "finished"
    setProcessingParts(bookableParts.bookableParts.map((part) => part.id))
    let accumulatedSchema = []
    // post to endpoints
    const promises = bookableParts.bookableParts.map(async (part) => {
      const blob = await getPartStl(part.id)

      // select relevant attributes from part
      const {
        id,
        name,
        id_part_client,
        company,
        attributes,
        float_attributes,
        status_attributes,
        dwh_materials,
      } = part

      const schema = {
        part: {
          id,
          name,
          id_part_client,
          company,
          attributes,
          float_attributes,
          status_attributes,
          dwh_materials,
          db_id_client: part.id, // set client part id
          files: [], // file will be uploaded separately
        },
        amount: values.amounts[part.id],
        material_id: values.materials[part.id],
        stl_file: blob, // additionally pass file to be uploaded to consolidation
      }

      setProcessingParts((processingParts) => {
        const updatedProcessingParts = processingParts.filter(
          (partId) => partId !== part.id,
        )
        if (updatedProcessingParts.length === 0) {
          setConfigState(true)
        }
        return updatedProcessingParts
      })
      return schema
    })
    accumulatedSchema = await Promise.all(promises)
    setAccumulatedFormData(accumulatedSchema)

    setOrderPartsListData({
      order_number: orderNumber,
      client_reference: values.client_reference,
      client_note: values.client_note,
      delivery_address: values.delivery_address,
      billing_address: isBillingSameAsDeliveryAddress
        ? values.delivery_address
        : values.billing_address,
      main_order: {
        orderpart_quantity: values.amounts,
        allow_partial_delivery: values.allow_partial_delivery,
        allow_other_suppliers: values.allow_other_suppliers,
        allow_subcontractors: values.allow_subcontractors,
        desired_delivery_date: values.desired_delivery_date,
      },
      list_of_suborders: values.subOrders
        ?.filter((subOrder) => subOrder !== undefined)
        .map((subOrder) => ({
          orderpart_quantity: subOrder.amounts,
          allow_partial_delivery: subOrder.allow_partial_delivery,
          allow_other_suppliers: subOrder.allow_other_suppliers,
          allow_subcontractors: subOrder.allow_subcontractors,
          desired_delivery_date: subOrder.desired_delivery_date,
        })),
    })
  }

  return (
    <>
      <StyledForm
        name="orderPartsForm"
        form={form}
        onFinish={onFinish}
        wrapperCol={{ span: 17 }}
        labelCol={{ span: 5 }}
        autoComplete="off"
        layout="horizontal"
        initialValues={{
          delivery_address: { ...companyData },
          billing_address_same_as_delivery: true,
          billing_address: { ...companyData },
          ...orderToRepeat?.list_of_orderparts.reduce(
            (acc, orderpart) => {
              acc.amounts[orderpart.part.db_id_client] =
                orderToRepeat.main_order.orderpart_quantity[orderpart.id]
              acc.materials[orderpart.part.db_id_client] = orderpart.material
              return acc
            },
            { amounts: {}, materials: {} },
          ),
        }}
        onValuesChange={(changedValues, allValues) =>
          updateMainAmount(form, changedValues, allValues)
        }
      >
        <Row className="row">
          <h4 style={{ padding: '15px 0' }}>
            {t('service.order_part.choose_amount_and_material')}
          </h4>
          <div style={{ marginRight: '50px' }}>
            <OrderPartsTable
              parts={bookableParts.bookableParts}
              materials={availableMaterials}
              processingParts={processingParts}
            />
          </div>
        </Row>
        <Divider />
        <Row>
          <Col flex="1 0 25%" className="formcolumn">
            <h4 style={{ padding: '15px 0' }}>
              {t('service.order_part.order_address.delivery_address')}
            </h4>
            <AddressFormItems prefix="delivery_address" />
            <Divider />
            <Form.Item style={{ margin: '10px 0 0 126px' }}>
              <Checkbox
                checked={isBillingSameAsDeliveryAddress}
                onChange={(e) => {
                  setIsBillingSameAsDeliveryAddress(e.target.checked)
                }}
              >
                {t('service.order_part.order_address.same_as_delivery_address')}
              </Checkbox>
            </Form.Item>
            {isBillingSameAsDeliveryAddress === false && (
              <>
                <h4 style={{ marginTop: '-26px', paddingBottom: '15px' }}>
                  {t('service.order_part.order_address.billing_address')}
                </h4>
                <AddressFormItems prefix="billing_address" />
              </>
            )}
          </Col>
          <Divider
            type="vertical"
            style={{ height: 'unset', margin: '60px 40px' }}
          />
          <Col flex="1 0 25%" className="formcolumn">
            <h4 style={{ padding: '15px 0' }}>{t('Additional information')}</h4>
            <Form.Item
              name="client_reference"
              label={t('service.order_part.client_reference')}
            >
              <Input
                placeholder={t(
                  'service.order_part.client_reference_placeholder',
                )}
              />
            </Form.Item>
            <Divider />
            <h4 style={{ padding: '15px 0' }}>
              {t('service.order_part.specifications')}
            </h4>
            <Form.Item
              name="client_note"
              label={t('service.order_part.additional_notes')}
            >
              <TextArea
                placeholder={t(
                  'service.order_part.additional_notes_placeholder',
                )}
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right', marginTop: '20px' }}>
          <Space>
            <Button type="primary" htmlType="submit">
              {t('button.save')}
            </Button>
          </Space>
        </Form.Item>
      </StyledForm>
      <Divider />
    </>
  )
}

export default StepTwoConfigOrderPart
